import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JWT_TOKEN_KEY } from "../../constants/APIConstants";
import { User } from "../../models/User";

export interface AuthState {
    userID: number | null;
    loggedInUser: User | null;
}

const initialState: AuthState = {
    userID: null,
    loggedInUser: null,
};

const authSlice = createSlice({
    name: 'auth',
    initialState, 
    reducers: {
        logout: (state) => {
            localStorage.removeItem(JWT_TOKEN_KEY);
            state.userID = null;
            state.loggedInUser = null;
        },
        login: (state, action: PayloadAction<{token: string; user_id: number}>) => {
            const { token, user_id } = action.payload;
            localStorage.setItem(JWT_TOKEN_KEY, token);
            state.userID = user_id;
        },
        setLoggedInUser: (state, action: PayloadAction<User>) => {
            state.loggedInUser = action.payload;
        }
    }
})

export const { logout, login, setLoggedInUser } = authSlice.actions;

export const LOGOUT_ACTION = logout.type;

export default authSlice.reducer;
