import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from './AppConfig';
import { JWT_TOKEN_KEY } from '../constants/APIConstants';
import { store } from '../redux/store';
import { logout } from '../redux/slices/authSlice';

const authService = axios.create({
  timeout: 60 * 1000, // 60 seconds
});

const AUTHORIZATION_HEADER_KEY = 'Authorization';

authService.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(JWT_TOKEN_KEY);
    if (config.headers) {
      config.headers[AUTHORIZATION_HEADER_KEY] = `Bearer ${jwtToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authService.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const response = error.response as AxiosResponse;
      if (response.status === 403) {
        store.dispatch(logout());
      }
    }
    return Promise.reject(error);
  }
);

export default authService;