import {Button, Checkbox, Form, Input, Select} from "antd";
import {AyrmeshButton} from "../../../../shared-components/ayrmesh-button/AyrmeshButton";
import './RegisterForm.css';
import {useEffect, useMemo, useState} from "react";
import {useUsernameAvailability} from "../hooks/useUsernameAvailability";
import debounce from "lodash/debounce";
import {LOGIN} from "../../../../constants/RoutePathConstants";
import {useNavigate} from "react-router-dom";
import countries from "../../../../assets/countries.json";
import timezones from "../../../../assets/time-zones.json";

type RegisterFormProps = {
  handleSubmit: any;
  isLoading: boolean;
}

export function RegisterForm({handleSubmit, isLoading}: RegisterFormProps) {
  const [username, setUsername] = useState<string | null>(null);
  const {isLoading: isUsernameAvailabilityLoading, usernameAvailable} = useUsernameAvailability(username)
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const debounceSearch = useMemo(() => {
    const searchByUsername = (username: string) => {
      if (username) {
        setUsername(username);
      } else {
        setUsername(null);
      }
    }
    return debounce(searchByUsername, 300);
  }, [setUsername]);

  const navigateToLogin = () => {
    navigate(LOGIN);
  }

  useEffect(() => {
    if (usernameAvailable !== null && usernameAvailable !== undefined) {
      form.validateFields(['username']);
    }
  }, [usernameAvailable])

  const noteStyle: any = {
    fontStyle: "italic",
    fontSize: 12,
    fontFamily: "monospace",
    textAlign: "justify",
  }


  const charValidator = (rule: any, value: string, callback: any) => {
    const pattern = /[$&?<>*|\/\\'“"]/;
    if (pattern.test(value)) {
      callback('Password must not contain the following characters: $, &, ?, <, >, *, | , /, \\, \', “');
    } else {
      callback();
    }
  };

  const timeZoneOptions = [...timezones].map((e) => {
        return {
          value: e,
          label: e
        };
      }
  );

  return (
    <div className="register-form-container">
      <Form
        form={form}
        layout="vertical"
        name="basic"
        onFinish={handleSubmit}
        autoComplete="off"
      >
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <div className="form-block">
            <Form.Item
                style={{marginBottom: 5}}
                label="Email"
                name="email"
                rules={[{type: "email"}, {required: true, message: 'Please input your email!'}]}
            >
              <Input />
            </Form.Item>
            <Form.Item
                style={{marginBottom: 5}}
                label="Username"
                name="username"
                rules={[
                  {required: true, message: 'Please input your username!'},
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || value.length < 4) return Promise.reject("Username must be at least 4 characters long");
                      const pattern = /[$&?<>*|\/\\'“"]/;
                      if (pattern.test(value)) {
                        return Promise.reject('Username must not contain the following characters: $, &, ?, <, >, *, | , /, \\, \', “');
                      }
                      if (usernameAvailable) {
                        return Promise.resolve();
                      }
                      return Promise.reject('Username is already taken');
                    },
                  })
                ]}
            >
              <Input onChange={(e) => debounceSearch(e.target.value)} />
            </Form.Item>

            <Form.Item
                style={{marginBottom: 5}}
                label="Password"
                name="password"
                rules={[
                  { required: true, message: 'Please input your password!' },
                  { validator: charValidator }
                ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
                style={{marginBottom: 5}}
                label="Confirm password"
                name="confirm_password"
                rules={[{
                  required: true,
                  message: 'Please confirm your password!'
                }, ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                  },
                }) ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Time Zone"
                name="time_zone"
            >
              <Select
                  showSearch={true}
                  options={timeZoneOptions}
              />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Country"
                name="country"
                rules={[{
                  max: 32,
                  message: 'Maximum number of characters is 32'
                }]}
            >
              <Select
                  showSearch={true}
                  filterOption={(input, option) => {
                    return (option?.label.toLowerCase() ?? '').includes(input);
                  }}
                  options={countries}
              />
            </Form.Item>

          </div>


          <div className="form-block">
            <Form.Item
                style={{ marginBottom: 5 }}
                label="First Name"
                name="first_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Last Name"
                name="last_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Phone Number"
                name="phone"
                rules={[{
                  max: 12,
                  message: 'Maximum number of digits is 12'
                }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Business Name"
                name="business_name"
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Mail Address"
                name="mail_address"
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="Mail Address 2"
                name="mail_address_2"
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="City"
                name="city"
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="State"
                name="state"
                rules={[{
                  max: 32,
                  message: 'Maximum number of characters is 32'
                }]}
            >
              <Input />
            </Form.Item>


            <Form.Item
                style={{ marginBottom: 5 }}
                label="Zip"
                name="zip"
                rules={[{
                  max: 32,
                  message: 'Maximum number of digits is 32'
                }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
                style={{ marginBottom: 5 }}
                label="County"
                name="county"
                rules={[{
                  max: 32,
                  message: 'Maximum number of characters is 32'
                }]}
            >
              <Input />
            </Form.Item>
          </div>
        </div>

        <Form.Item
            name="subscribed_to_newsletter"
            initialValue={true}
            valuePropName="checked"
        >
          <Checkbox>
            Subscribe to Ayrstone Newsletter
          </Checkbox>
        </Form.Item>

        <Form.Item
          label="How did you hear about us?"
          name="referer"
          rules={[{ required: true, message: 'Please select the value!' }]}
          style={{marginBottom: 5}}
        >
          <Select>
            <Select.Option value="magazine-ad">Magazine ad</Select.Option>
            <Select.Option value="magazine-content">Magazine article</Select.Option>
            <Select.Option value="online-ad">Online ad</Select.Option>
            <Select.Option value="online-content">Blog post or other online articles</Select.Option>
            <Select.Option value="tv">TV</Select.Option>
            <Select.Option value="radio">Radio</Select.Option>
            <Select.Option value="other">Other</Select.Option>
          </Select>
        </Form.Item>
        <p style={noteStyle}>
          The password on your devices will match the password on this website.
          It is recommended that you use a unique password for this service.
        </p>
       <Form.Item style={{marginBottom: 10}}>
          <AyrmeshButton loading={isLoading} block type="primary" htmlType="submit">
            Register
          </AyrmeshButton>
        </Form.Item>
        <p style={{textAlign: 'center'}}><Button onClick={() => navigateToLogin()} type="link">Sign in</Button></p>
      </Form>
    </div>
  );
}

RegisterForm.defaultProps = {
  isLoading: false,
};
